(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

$(function () {

    var cont = document.getElementById('background_images');

    function shiftImages() {
        cont.insertBefore(cont.querySelector('div:last-child'), cont.firstChild);
        animate();
    }

    function animate() {
        setTimeout(shiftImages, 5000);
    }

    animate();
});

},{}],2:[function(require,module,exports){
'use strict';

$(function () {

    $('.carousel_wrapper').removeClass('hidden').each(function (index, elm) {
        elm = $(elm);
        elm.slick({
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: elm.hasClass('tall') ? 5 : 3,
            slidesToScroll: 3,
            //centerMode: true,
            //variableWidth: true,
            responsive: [{
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
        });
    });
});

$(window).load(function () {

    'use strict';

    $('.vertical_carousel_wrapper').removeClass('hidden').slick({
        dots: false,
        infinite: false,
        speed: 300,
        //slidesToShow: 1,
        slidesToScroll: 2,
        vertical: true,
        adaptiveHeight: true
    });
});

},{}],3:[function(require,module,exports){
'use strict';

$(function () {

    "use strict";

    if (!Cookies.get('euCookies')) {
        $('#eu-cookie-box').show();
    }

    $('#eu-cookie-box .close').click(function (e) {
        e.preventDefault();
        Cookies.set('euCookies', '1', { expires: 365 });
        $('#eu-cookie-box').hide();
    });
});

},{}],4:[function(require,module,exports){
'use strict';

$(function () {

    $('.subpage-sidebar li').click(function (e) {
        //e.preventDefault();

        if ($(e.target).closest('li')[0] == this) {
            $(this).toggleClass('expanded');
        }
    });
});

},{}],5:[function(require,module,exports){
'use strict';

$(function () {

    $('.favorite a').click(function (e) {
        e.preventDefault();

        var elm = $(this);
        var id = elm.closest('.favorite').data('record-id');
        var url = elm.closest('.favorite').data('url').replace('__id__', id);

        $.post(url).then(function (result) {
            elm.closest('.favorite').find('a').toggleClass('active');
        }).fail(function (result) {}).done(function () {});
    });
});

},{}],6:[function(require,module,exports){
'use strict';

$(window).load(function () {

    'use strict';

    $('.folk_btn.facebook').click(function (e) {
        e.preventDefault();

        FB.login(function (response) {
            statusChangeCallback(response);
        }, { scope: 'email' });
    });

    function statusChangeCallback(response) {
        if (response.status === 'connected') {
            FB.api('/me?fields=id,name,email', function (data) {
                //console.log('Successful login for: ', response);
                $.post('/fb_login', data).done(function (result) {
                    //$('body').trigger('front.login.success', [data]);
                    window.location.href = result.url;
                }).fail(function (result) {});
            });
        } else {
            console.debug(response);
        }
    }

    //function checkLoginState() {
    //    FB.getLoginStatus(function(response) {
    //        statusChangeCallback(response);
    //    });
    //}

    window.fbAsyncInit = function () {
        FB.init({
            appId: window.facebookAppId,
            cookie: true, // enable cookies to allow the server to access
            version: 'v2.5' // use version 2.2
        });
    };

    // Load the SDK asynchronously
    (function (d, s, id) {
        var js,
            fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);js.id = id;
        js.src = "//connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
});

},{}],7:[function(require,module,exports){
'use strict';

$(function () {

    lightbox.option({
        'resizeDuration': 200,
        'wrapAround': true,
        'albumLabel': 'Obrázek %1 z %2'
    });

    // videos from CKEditor
    $("iframe[src^='//www.youtube.com']").wrap('<div class="youtube-embed-wrapper"></div>');

    // source: http://css-tricks.com/NetMag/FluidWidthVideo/Article-FluidWidthVideo.php
    // Find all YouTube videos
    var $allVideos = $("iframe[src^='https://www.youtube.com'], iframe[src^='//www.youtube.com']"),

    // The element that is fluid width
    $fluidEl = $("body");

    // Figure out and save aspect ratio for each video
    $allVideos.each(function () {
        $(this).data('aspectRatio', this.height / this.width)
        // and remove the hard coded width/height
        .removeAttr('height').removeAttr('width');
    });

    // When the window is resized
    $(window).resize(function () {

        var newWidth = $fluidEl.width();

        // Resize all videos according to their own aspect ratio
        $allVideos.each(function () {
            var $el = $(this);

            var w;
            if (newWidth > 800) {
                w = 600;
            } else {
                w = newWidth + 40;
            }

            $el.width(w).height(w * $el.data('aspectRatio'));
        });

        // Kick off one resize to fix all videos on page load
    }).resize();
});

},{}],8:[function(require,module,exports){
'use strict';

$(function () {

    'use strict';

    var desktopBottomPadding = 10;
    var mobileBottomPadding = 0.02;
    var isMobile = false;

    var header = $('#hp_header');

    function resizeHeader() {
        isMobile = window.innerWidth <= 840;
        var height;
        if (isMobile) {
            height = window.innerHeight * (1.0 - mobileBottomPadding);
        } else {
            height = window.innerHeight - desktopBottomPadding;
        }
        header.height(height < 450 ? 450 : height);
    }
    resizeHeader();

    $(window).resize(resizeHeader);
});

},{}],9:[function(require,module,exports){
'use strict';

$(function () {

    $('.navbar_content.login form, #login-tab form').find('input').keypress(function (e) {
        if (e.keyCode == 13) {
            $(this).closest('form').submit();
        }
    });

    $('.navbar_content.login form, #login-tab form').submit(function (e) {
        e.preventDefault();
        var form = $(this);
        form.find('> div').removeClass('error');

        $.post(form.attr('action'), form.serialize()).always(function (result) {
            if (result.error) {
                form.find('> div').addClass('error');
            } else if (result.url) {
                window.location.href = result.url;
                //$('body').trigger('front.login.success', [result]);
            }
        });
    });

    $('.navbar_content.login .folk_btn.send, #login-tab .folk_btn.btn_red').click(function (e) {
        e.preventDefault();
        $(this).closest('form').submit();
    });
});

},{}],10:[function(require,module,exports){

//import './svg';

'use strict';

require('./plugin/routableTabs.plugin');

require('./homepage');

require('./map');

require('./background');

require('./carousel');

require('./gallery');

require('./search');

require('./menu');

require('./upload');

require('./fb_login');

require('./login');

require('./registration');

require('./user');

require('./favorites');

require('./song');

require('./detail');

require('./noteflight');

require('./cookie');

},{"./background":1,"./carousel":2,"./cookie":3,"./detail":4,"./favorites":5,"./fb_login":6,"./gallery":7,"./homepage":8,"./login":9,"./map":11,"./menu":12,"./noteflight":13,"./plugin/routableTabs.plugin":14,"./registration":15,"./search":16,"./song":17,"./upload":18,"./user":19}],11:[function(require,module,exports){
'use strict';

(function () {

    'use strict';

    var map = null;

    var allMarkers = [];

    window.updateMarkers = function (markers) {
        window.clearMapMarkers();

        var positions = [];

        markers.forEach(function (position, index) {
            var pos = new google.maps.LatLng(position.lat, position.lng);

            positions.push(pos);

            var image = {
                url: '/assets/images/map_marker.png',
                scaledSize: new google.maps.Size(40, 64)
            };
            var marker = new google.maps.Marker({
                'position': pos,
                'icon': image,
                'map': map
            });
            marker.addListener('click', function () {
                var win = window.open(position.url, '_blank');
                win.focus();
            });
            allMarkers.push(marker);
        });

        var bounds = new google.maps.LatLngBounds();
        // extends bounds
        for (var i = 0; i < positions.length; i++) {
            bounds.extend(positions[i]);
        }

        map.fitBounds(bounds);

        var listener = google.maps.event.addListener(map, "idle", function () {
            map.setZoom(map.getZoom() - 1);
            google.maps.event.removeListener(listener);
        });
    };

    window.clearMapMarkers = function () {
        for (var i = 0; i < allMarkers.length; i++) {
            allMarkers[i].setMap(null);
        }
        allMarkers = [];
    };

    window.initializeMap = function () {
        var options = {
            center: {
                lat: 49.01,
                lng: 17.63
            },
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_CENTER
            },
            mapTypeControl: false,
            zoom: 13,
            scrollwheel: false
        };

        $('.gmap').each(function (index, elm) {
            map = new google.maps.Map(elm, options);

            window.updateMarkers(window.mapMarkers);
        });
    };

    function initGoogleMap() {
        if (!document.getElementById('mapscript')) {
            var script = document.createElement('script');
            script.id = 'mapscript';
            script.type = 'text/javascript';
            script.src = 'https://maps.googleapis.com/maps/api/js?sensor=false&callback=initializeMap';
            document.body.appendChild(script);
        }
    }

    $(function () {
        initGoogleMap();
    });
})();

},{}],12:[function(require,module,exports){
'use strict';

$(function () {

    'use strict';

    var menu = $('.navbar');
    var loginBar = $('.navbar_content.login');
    var searchBar = $('.search_bar');

    var doc = $(document);
    var header = $('#hp_header');
    var menuHeight = menu.height();
    //var logo = $('#svg_logo');
    var isHomepage = !menu.hasClass('navbar-subpage');

    function closeAll(exceptElm) {
        var alreadyOpened = false;
        if (exceptElm !== loginBar && loginBar.hasClass('visible')) {
            $('#menu_user').click();
            alreadyOpened = true;
        }

        if (exceptElm !== menu && menu.hasClass('expanded')) {
            $('.hamburger').click();
            alreadyOpened = true;
        }

        if (exceptElm !== searchBar && searchBar.hasClass('visible')) {
            $('#menu_search').click();
            alreadyOpened = true;
        }

        return alreadyOpened;
    }

    function checkMenu() {
        if (loginBar.hasClass('visible') || searchBar.hasClass('visible')) {
            return;
        }

        if (menu.hasClass('expanded')) {
            //coloredMenu();
        } else if (menuHeight < doc.scrollTop()) {
                if (!menu.hasClass('fixed')) {
                    menu.addClass('fixed white_bg');
                    //coloredMenu();
                }
            } else {
                    if (menu.hasClass('fixed')) {
                        menu.removeClass('fixed');
                    }
                    if (!menu.hasClass('navbar-subpage')) {
                        menu.removeClass('white_bg');
                    }
                    //whiteMenu();
                }

        if (header.height() < doc.scrollTop()) {
            menu.addClass('fixed_anim');
        } else {
            menu.removeClass('fixed_anim');
        }
    }

    if (isHomepage) {
        doc.scroll(checkMenu);
        checkMenu();
    }

    var noAnimateTimeout;

    function hideMenu(keepWhite) {
        if (isHomepage && !menu.hasClass('fixed_anim')) {
            setTimeout(function () {
                if (!keepWhite) {
                    checkMenu();
                }

                clearTimeout(noAnimateTimeout);
                noAnimateTimeout = setTimeout(function () {
                    menu.removeClass('animate');
                }, 1000);
            }, 300);
        } else {
            noAnimateTimeout = setTimeout(function () {
                menu.removeClass('animate');
            }, 300);
        }
        menu.removeClass('expanded');
    }

    function showMenu() {
        clearTimeout(noAnimateTimeout);

        menu.addClass('animate');
        if (isHomepage) {
            if (menu.hasClass('white_bg')) {
                menu.addClass('expanded');
            } else {
                setTimeout(function () {
                    menu.addClass('expanded');
                }, 300);
            }
        } else {
            menu.addClass('expanded');
        }
        menu.addClass('white_bg');
    }

    function showSearchMenu() {
        clearTimeout(noAnimateTimeout);
        setTimeout(function () {
            searchBar.addClass('visible');
            menu.addClass('fixed_anim fixed');
        }, menu.hasClass('white_bg') ? 0 : 500);

        menu.addClass('animate white_bg');
    }

    function hideSearchMenu(keepWhite) {
        searchBar.removeClass('visible');
        setTimeout(function () {
            if (!keepWhite) {
                checkMenu();
            }

            clearTimeout(noAnimateTimeout);
            noAnimateTimeout = setTimeout(function () {
                searchBar.removeClass('animate');
            }, 1000);
        }, 500);
    }

    function hideUserMenu(keepWhite) {
        loginBar.removeClass('visible');
        setTimeout(function () {
            if (!keepWhite) {
                checkMenu();
            }

            clearTimeout(noAnimateTimeout);
            noAnimateTimeout = setTimeout(function () {
                menu.removeClass('animate');
            }, 1000);
        }, 500);
    }

    function showUserMenu() {
        clearTimeout(noAnimateTimeout);
        setTimeout(function () {
            loginBar.addClass('visible');
            menu.addClass('fixed_anim fixed');
        }, menu.hasClass('white_bg') ? 0 : 500);

        menu.addClass('animate white_bg');
    }

    $('.hamburger').click(function (e) {
        e.preventDefault();

        setTimeout(function () {
            if (isHomepage && menuHeight < doc.scrollTop()) {
                menu.addClass('fixed');
            }

            var keepWhite = closeAll(menu);

            if (menu.hasClass('expanded')) {
                // hide
                hideMenu(keepWhite);
            } else {
                // show
                showMenu();
            }
        }, closeAll(menu) ? 500 : 0);
    });

    $('#menu_search').click(function (e) {
        e.preventDefault();

        var keepWhite = closeAll(searchBar);

        if (searchBar.hasClass('visible')) {
            hideSearchMenu(keepWhite);
        } else {
            showSearchMenu();
        }
    });

    if (!$('#menu_user').hasClass('logged')) {
        $('#menu_user').click(function (e) {
            e.preventDefault();

            var keepWhite = closeAll(loginBar);

            if (loginBar.hasClass('visible')) {
                hideUserMenu(keepWhite);
            } else {
                showUserMenu();
            }
        });
    }
});

},{}],13:[function(require,module,exports){
// Initialize the Noteflight client API.

'use strict';

if (window.NFClient) {
    NFClient.init(function (info) {
        //alert("Noteflight API is ready, version " + info.version);

        $('.noteflight').each(function (i, elm) {
            var semitones = 0;

            var score = new NFClient.ScoreView($(elm).find('iframe')[0]);

            $(elm).find('.controls .transpose-up').click(function (e) {
                e.preventDefault();
                semitones += 1;
                score.transpose({ 'semitones': semitones });
            });
            $(elm).find('.controls .transpose-down').click(function (e) {
                e.preventDefault();
                semitones -= 1;
                score.transpose({ 'semitones': semitones });
            });

            //score1.addEventListener('editorReady', handleEditorReady);   // wait for ready event
        });
    });
}

},{}],14:[function(require,module,exports){
'use strict';

(function ($) {
    $.fn.routableTabs = function (options) {
        options = $.extend({
            'activeClass': 'active',
            'checkInterval': 200
        }, options);

        var tabs = $(this);
        var previousUrl;

        tabs.each(function () {
            var title = $(this).data('title');
            var url = $(this).data('url');
            var tab = $(this).attr('href');
            var elm = $(this);

            function switchTab() {
                tabs.each(function () {
                    var t = $(this).attr('href');
                    if (tab === t) {
                        $(t).show();
                    } else {
                        $(t).hide();
                    }
                });

                $('title').text(title);
                previousUrl = url;
                window.history.pushState(null, null, url);
            }

            $(this).click(function (e) {
                e.preventDefault();

                tabs.removeClass(options.activeClass);
                elm.addClass(options.activeClass);

                switchTab();
            });
        });

        // Check current URL
        function checkURL() {
            var currentUrl = window.location.pathname;
            if (previousUrl != currentUrl || !previousUrl) {
                var matchingTab = tabs.filter('[data-url="' + currentUrl + '"]');
                if (matchingTab.length > 0) {
                    matchingTab.click();
                }
                previousUrl = currentUrl;
            }
        }

        var interval = setInterval(function () {
            checkURL();
        }, options.checkInterval);

        return this;
    };
})(jQuery);

},{}],15:[function(require,module,exports){
"use strict";

$(function () {});

},{}],16:[function(require,module,exports){
'use strict';

$(function () {

    var inputs = $('#subpage_content ul.filter input');
    var urlTemplate = inputs.closest('ul').data('url');
    var isDetail = $('#subpage_content').hasClass('is_detail');

    //console.log(isDetail);

    var moreButton = $('#subpage_content .more_results .folk_btn');
    var list = $('#subpage_content .items-list');

    moreButton.click(function (e) {
        e.preventDefault();
        if (moreButton.hasClass('disabled')) {
            return;
        }

        moreButton.addClass('disabled');

        var offset = list.find('> div').length;
        updateUrlBar(offset);
    });

    function updateUrlBar(offset, term) {
        var types = [];

        inputs.filter(':checked').each(function (index, elm) {
            types.push($(elm).val());
        });

        var url = urlTemplate.replace('__placeholder__', types.join(',')).replace(/\/$/, '');
        if (offset) {
            url += '?od=' + offset;
        } else {
            window.history.pushState('search filter changed', 'search filter changed', url);
        }

        list.stop().animate({
            'opacity': 0.25
        }, 500);

        $.get(url).done(function (response) {
            if (offset) {
                list.append(response.html);
            } else {
                list.html(response.html);
            }
            list.stop().animate({
                'opacity': 1.0
            }, 500);

            if (response.hasMore) {
                moreButton.parent().removeClass('invisible');
            } else {
                moreButton.parent().addClass('invisible');
            }
            moreButton.removeClass('disabled');
        }).fail(function (response) {
            console.log(response);
        });
    }

    function scrollToItems() {
        $('html, body').animate({
            scrollTop: $('.items-list').offset().top - $('.navbar').outerHeight(),
            easing: 'linear'
        }, 1000);
    }

    inputs.change(function (e) {
        updateUrlBar();
        scrollToItems();
    });

    $('.subpage-anchors button.map').click(function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $('#map_overlayed_container').offset().top - $('.navbar').outerHeight(),
            easing: 'linear'
        }, 1000);
    });

    $('.subpage-anchors button.items').click(function (e) {
        e.preventDefault();
        scrollToItems();
    });

    var previewImage = $('.vertical_carousel_wrapper').closest('.row').find('.col-md-9 > img');
    $('.vertical_carousel_wrapper img').click(function (e) {
        previewImage.attr('src', $(this).attr('src'));
    });

    if (isDetail) {
        var checkFixedMenu = function checkFixedMenu(e) {
            var scroll = doc.scrollTop();
            var treshold = mainTopOffset - menuHeight;
            if (scroll > treshold) {
                var top;
                if (scroll + sidebarHeight - 20 > bottomLimit) {
                    top = bottomLimit - sidebarHeight - mainTopOffset;
                } else {
                    top = scroll - mainTopOffset + 20;
                }
                //console.log(scroll + sidebarHeight > bottomLimit, bottomLimit);
                sidebar.addClass('fixed').css('top', top + 'px');
            } else {
                sidebar.removeClass('fixed');
            }
        };

        var doc = $(document);
        var sidebar = $('.subpage-sidebar');
        var sidebarHeight = sidebar.outerHeight();
        var mainContent = $('.subpage-content-main');
        var mainTopOffset = mainContent.position().top;
        var menuHeight = $('.navbar').outerHeight();
        var bottomLimit = $('#map_overlayed_container').position().top;

        checkFixedMenu();
        doc.scroll(checkFixedMenu);
    }

    $('.search_bar form').each(function (index, elm) {
        var form = $(elm);
        var searchUrl = form.attr('action');
        form.submit(function (e) {
            e.preventDefault();
            var term = form.find('input').val();
            window.location.href = searchUrl + '?vyraz=' + term;
        });
    });
});

},{}],17:[function(require,module,exports){
'use strict';

$(function () {

    $('audio').mediaelementplayer();
});

},{}],18:[function(require,module,exports){
// http://jsfiddle.net/brianblakely/h3EmY/
/* HTML5 <template> POLYFILL */
'use strict';

(function templatePolyfill(d) {
    if ('content' in d.createElement('template')) {
        return false;
    }

    var qPlates = d.getElementsByTagName('template'),
        plateLen = qPlates.length,
        elPlate,
        qContent,
        contentLen,
        docContent;

    for (var x = 0; x < plateLen; ++x) {
        elPlate = qPlates[x];
        qContent = elPlate.childNodes;
        contentLen = qContent.length;
        docContent = d.createDocumentFragment();

        while (qContent[0]) {
            docContent.appendChild(qContent[0]);
        }

        elPlate.content = docContent;
    }
})(document);

$(function () {

    'use strict';

    var maxFilesSize = parseInt($('#upload-tab form').data('max-size')) * Math.pow(10, 6);

    $('.list-inline.tabs > li > a').routableTabs();

    // on user profile edit event
    $('body').on('front.user.edit', function (e, user) {
        var uploadFormInputs = $('#upload-tab input');
        uploadFormInputs.filter('[name$="[name]"], [name$="[email]"]').each(function () {
            $(this).attr('disabled', true);
        });
        uploadFormInputs.filter('[name$="[email]"]').val(user.email);
        uploadFormInputs.filter('[name$="[name]"]').val(user.name);
    });

    $('.description .transparent_btns .folk_btn').click(function (e) {
        e.preventDefault();
        var tab = $(this).data('tab');

        $('html, body').animate({
            scrollTop: $('ul.tabs').offset().top - $('.navbar').outerHeight()
        }, {
            complete: function complete() {
                $('#subpage_header_image .tabs > li > a[href="' + tab + '"]').click();
            }
        }, 500);
    });

    $('#registration-tab .folk_btn').click(function (e) {
        e.preventDefault();

        var btn = $(this);
        var form = btn.closest('form');

        if (btn.hasClass('disabled')) {
            return;
        }

        var url = form.attr('action');

        form.find('div').removeClass('error');

        $.post(url, form.serialize()).fail(function (data) {
            showFormErrors(form, data.responseJSON['formErrors']);
            form.find('input[name$="[_csrf_token]"]').val(data.responseJSON['newCSRFToken']);
        }).done(function (data) {
            //window.location.href = data.url;
            form.find('input:not(:hidden), textarea').val('');
            form.find('.success_message').slideDown(250);
            //setTimeout(() => {
            //    form.find('.success_message').slideUp(250);
            //}, 5000);
        });
    });

    $('#upload-tab .folk_btn.btn_red').click(function (e) {
        e.preventDefault();
        var btn = $(this);
        var form = btn.closest('form');

        if (btn.hasClass('disabled')) {
            return;
        }

        form.find('div').removeClass('error');

        btn.addClass('disabled with_progress');

        var progressBar = btn.find('.progressbar > div');
        progressBar.stop().css('width', 0);

        function progressWithProgressBar(percentComplete) {
            var defer = $.Deferred();
            progressBar.stop().animate({
                width: percentComplete + '%'
            }, {
                duration: 250,
                easing: 'linear',
                done: function done() {
                    defer.resolve();
                }
            });
            return defer.promise();
        }

        $.ajax({
            xhr: function xhr() {
                var xhr = $.ajaxSettings.xhr();
                if (xhr.upload) {
                    // Check if upload property exists
                    xhr.upload.addEventListener('progress', function (e) {
                        if (e.lengthComputable) {
                            var percentComplete = e.loaded / e.total;
                            progressWithProgressBar(percentComplete * 100);
                        }
                    }, false); // For handling the progress of the upload
                }
                return xhr;
            },
            url: form.attr('action'),
            type: 'POST',
            data: new FormData(form[0]),
            cache: false,
            contentType: false,
            processData: false
        }).done(function (data) {
            btn.find('span.status').text(String.fromCharCode(parseInt("e916", 16)));
            form.find('input:not(:hidden):not(:disabled), textarea').val('');
            form.find('.success_message').slideDown(250);
            //setTimeout(() => {
            //    form.find('.success_message').slideUp(250);
            //}, 5000);
            removeAllFiles();
        }).fail(function (data) {
            btn.find('span.status').text(String.fromCharCode(parseInt("e915", 16)));
            // Update CSRF token
            if (data.responseJSON) {
                form.find('input[name$="[_csrf_token]"]').val(data.responseJSON['newCSRFToken']);
                showFormErrors(form, data.responseJSON['formErrors']);
            }
        }).always(function () {
            progressWithProgressBar(100.0).then(function () {
                btn.removeClass('disabled with_progress').addClass('finished_success');
                setTimeout(function () {
                    btn.removeClass('finished_success');
                }, 3000);
            });
        });
    });

    var files = $('.files');
    var hiddenState = {
        'left': -400,
        'opacity': 0.0
    };
    var defaultState = {
        'left': 0,
        'opacity': 1.0
    };
    var fileInputTemplate = files.find('> template').html();

    files.on('click', 'button:not(.more)', function (e) {
        e.preventDefault();
        removeFile($(this).closest('span'));
    });

    var btn = files.find('button.more');
    btn.click(function (e) {
        e.preventDefault();
        btn.parent().find('input').click();
    });

    function removeAllFiles() {
        $('.files > span:not(:last)').each(function (i, elm) {
            return removeFile($(elm));
        });
    }

    function removeFile(elm) {
        elm.stop().animate(hiddenState, {
            duration: 250,
            easing: 'linear',
            done: function done() {
                elm.slideUp(250, function () {
                    elm.remove();
                });
            }
        });
    }

    function currentTotalFilesSize() {
        var total = 0;
        $('.files > span:not(:last) > input:file').each(function (i, input) {
            total += input.files[0].size;
        });
        return total;
    }

    function filesSizeCorrect(file) {
        if (currentTotalFilesSize() + file.size > maxFilesSize) {
            // too large
            $('.files .file_too_large').slideDown();
            return false;
        } else {
            $('.files .file_too_large').slideUp();
            return true;
        }
    }

    function createNewInput() {
        var cont = btn.parent();
        cont.append(fileInputTemplate);
        cont.find('input:file').change(function (e) {
            var file = e.target.files[0];

            if (filesSizeCorrect(file)) {
                appendFile(file);
                $(e.target).appendTo(files.find('span:eq(-2)'));
            } else {
                // reset file input field
                $(this).remove();
            }
            createNewInput();
        });
    }
    createNewInput();

    function appendFile(file) {
        var defer = $.Deferred();
        var html = '<span>' + file.name + ' (' + bytesToSize(file.size) + ')<button type="button">&#xe90f;</button></span>';
        files.find('span:last').before(html);
        var newSpan = files.find('span:eq(-2)');
        var height = newSpan.outerHeight();
        var borderWidth = parseInt(newSpan.css("border-left-width"), 10);
        var marginTop = parseInt(newSpan.css('margin-top'), 10);

        var hidden = $.extend({
            'height': 0,
            'margin-top': -2 * borderWidth
        }, hiddenState);
        var visible = {
            'height': height,
            'margin-top': marginTop + 'px'
        };

        newSpan.css(hidden).animate(visible, {
            duration: 150,
            easing: 'linear',
            done: function done() {
                newSpan.animate(defaultState);
                defer.resolve();
            }
        });
        return defer.promise;
    }

    function showFormErrors(form, errors) {
        Object.keys(errors).forEach(function (inputName) {
            form.find('input[name="' + inputName + '"]').closest('div').addClass('error');
        });
    }

    function bytesToSize(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }
});

},{}],19:[function(require,module,exports){
'use strict';

$(function () {

    var userCont = $('.user');

    function toggleInputs() {
        // toggle controls
        userCont.find('dd.controls').find('> a').toggleClass('hidden');

        var inputs = userCont.find('input, textarea, .dropzone').toggleClass('hidden');
        inputs.each(function (e) {
            var input = $(this);
            var cont = input.parent();
            cont.find('> span, > p').toggleClass('hidden');
        });
    }

    userCont.on('click', '.edit-profile', function (e) {
        e.preventDefault();
        toggleInputs();
    });

    userCont.on('click', '.edit-profile-cancel', function (e) {
        e.preventDefault();
        if (oldProfileImagePath) {
            $('#user-settings-tab .profile_photo > img').attr('src', oldProfileImagePath);
        }
        toggleInputs();
    });

    userCont.on('click', '.edit-profile-save', function (e) {
        e.preventDefault();

        var form = $(this).closest('form');
        $.post(form.attr('action'), form.serialize()).done(function (data) {
            $('#user-settings-tab').html(data.html);
            $('body').trigger('front.user.edit', [data.user]);

            initDropZone();
            refreshOldPRofilePhoto();
        }).fail(function (data) {
            showFormErrors(form, data.responseJSON['formErrors']);
            form.find('input[name$="[_csrf_token]"]').val(data.responseJSON['newCSRFToken']);
        });
    });

    var oldProfileImagePath;

    function refreshOldPRofilePhoto() {
        oldProfileImagePath = $('#user-settings-tab .profile_photo > img').attr('src');
    }

    function initDropZone() {
        $('#user-settings-tab .dropzone').dropzone({
            url: "/uzivatel/nahrat-fotku",
            dictDefaultMessage: 'Drag & Drop',
            createImageThumbnails: false,
            maxFilesize: 2,
            success: function success(a, response) {
                var imgElm = $('#user-settings-tab .profile_photo > img');
                imgElm.attr('src', response.imageThumbnailUrl);

                $('#user-settings-tab form input:hidden[name$="[new_image]"]').val(true);
            },
            acceptedFiles: 'image/*'
        });
    }

    function showFormErrors(form, errors) {
        Object.keys(errors).forEach(function (inputName) {
            form.find('input[name="' + inputName + '"]').closest('dt').addClass('error');
        });
    }

    refreshOldPRofilePhoto();
    initDropZone();
});

},{}]},{},[10]);
